.app-header {
    background-color: #010029;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.download-link {
    color: #FFFFFF;
}

.logo {
    height: 400px;
    width: 400px;
}

.logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Other properties as needed */
}

.download-logo-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 100px 10px 0 10px;
}

.download-logo {
    height: 40px;
}

.logo-name {
    margin: 0 auto;
    width: 250px;
    height: auto;
}

@font-face {
    font-family: 'MyCoolvetica';
    src: url('MyCoolvetica.otf') format('opentype');
}

.sub-logo-text {
    font-family: "MyCoolvetica";
    /*font-weight: 600;*/
    font-size: 23px;
    /*letter-spacing: 0.1em;*/
}

.footer {
    font-size: 12px;
}

.follow-icon {
    height: 20px;
    width: auto;
}