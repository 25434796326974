.AddEvent {
    display: flex;
    flex-direction: column;

    width: 400px;
    margin: 5px auto;
}

.AddEvent label {
    color: white;
    margin: 10px 0;
}

.AddEvent input,
.AddEvent textarea,
.AddEvent button,
.AddEvent .react-datepicker-wrapper {
    width: 400px;
    font-size: 18px;
    margin-top: 5px;
}

.error {
    color: red;
    margin-top: 3px;
}